"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.portalWebRequestPolicies = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _redirectToLoginPolicy = require("./redirectToLoginPolicy");

var _ticketRequestPolicy = require("./ticketRequestPolicy");

var _httpStatusCodePolicy = require("./httpStatusCodePolicy");

var portalWebRequestPolicies = function portalWebRequestPolicies(isAuthenticated, ticketId) {
  return function (defaultPolicies) {
    return [].concat((0, _toConsumableArray2["default"])(ticketId ? [(0, _ticketRequestPolicy.ticketRequestPolicy)(ticketId)] : []), [(0, _redirectToLoginPolicy.redirectToLoginPolicy)(isAuthenticated), _httpStatusCodePolicy.httpStatusCodePolicy], (0, _toConsumableArray2["default"])(defaultPolicies));
  };
};

exports.portalWebRequestPolicies = portalWebRequestPolicies;