"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _account = require("./account");

Object.keys(_account).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _account[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _account[key];
    }
  });
});

var _aiAssistant = require("./aiAssistant");

Object.keys(_aiAssistant).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _aiAssistant[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _aiAssistant[key];
    }
  });
});

var _annotation = require("./annotation");

Object.keys(_annotation).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _annotation[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _annotation[key];
    }
  });
});

var _archive = require("./archive");

Object.keys(_archive).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _archive[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _archive[key];
    }
  });
});

var _assetCollection = require("./assetCollection");

Object.keys(_assetCollection).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _assetCollection[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _assetCollection[key];
    }
  });
});

var _assetCollectionItem = require("./assetCollectionItem");

Object.keys(_assetCollectionItem).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _assetCollectionItem[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _assetCollectionItem[key];
    }
  });
});

var _assetCollectionRelease = require("./assetCollectionRelease");

Object.keys(_assetCollectionRelease).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _assetCollectionRelease[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _assetCollectionRelease[key];
    }
  });
});

var _assetCollectionTemplate = require("./assetCollectionTemplate");

Object.keys(_assetCollectionTemplate).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _assetCollectionTemplate[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _assetCollectionTemplate[key];
    }
  });
});

var _document = require("./document");

Object.keys(_document).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _document[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _document[key];
    }
  });
});

var _documentPdfPrint = require("./documentPdfPrint");

Object.keys(_documentPdfPrint).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _documentPdfPrint[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _documentPdfPrint[key];
    }
  });
});

var _documentAttachment = require("./documentAttachment");

Object.keys(_documentAttachment).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _documentAttachment[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _documentAttachment[key];
    }
  });
});

var _documentFamily = require("./documentFamily");

Object.keys(_documentFamily).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _documentFamily[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _documentFamily[key];
    }
  });
});

var _documentRelation = require("./documentRelation");

Object.keys(_documentRelation).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _documentRelation[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _documentRelation[key];
    }
  });
});

var _documentRelationGroup = require("./documentRelationGroup");

Object.keys(_documentRelationGroup).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _documentRelationGroup[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _documentRelationGroup[key];
    }
  });
});

var _documentSection = require("./documentSection");

Object.keys(_documentSection).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _documentSection[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _documentSection[key];
    }
  });
});

var _documentSubscriptions = require("./documentSubscriptions");

Object.keys(_documentSubscriptions).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _documentSubscriptions[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _documentSubscriptions[key];
    }
  });
});

var _documentVersion = require("./documentVersion");

Object.keys(_documentVersion).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _documentVersion[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _documentVersion[key];
    }
  });
});

var _documentVersionSubscription = require("./documentVersionSubscription");

Object.keys(_documentVersionSubscription).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _documentVersionSubscription[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _documentVersionSubscription[key];
    }
  });
});

var _documentView = require("./documentView");

Object.keys(_documentView).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _documentView[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _documentView[key];
    }
  });
});

var _email = require("./email");

Object.keys(_email).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _email[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _email[key];
    }
  });
});

var _featureFlag = require("./featureFlag");

Object.keys(_featureFlag).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _featureFlag[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _featureFlag[key];
    }
  });
});

var _legalAttributes = require("./legalAttributes");

Object.keys(_legalAttributes).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _legalAttributes[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _legalAttributes[key];
    }
  });
});

var _legalStatus = require("./legalStatus");

Object.keys(_legalStatus).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _legalStatus[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _legalStatus[key];
    }
  });
});

var _localizations = require("./localizations");

Object.keys(_localizations).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _localizations[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _localizations[key];
    }
  });
});

var _mainPageTiles = require("./mainPageTiles");

Object.keys(_mainPageTiles).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _mainPageTiles[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _mainPageTiles[key];
    }
  });
});

var _mergeDocument = require("./mergeDocument");

Object.keys(_mergeDocument).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _mergeDocument[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _mergeDocument[key];
    }
  });
});

var _pageConfigurations = require("./pageConfigurations");

Object.keys(_pageConfigurations).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _pageConfigurations[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _pageConfigurations[key];
    }
  });
});

var _paragraphZoom = require("./paragraphZoom");

Object.keys(_paragraphZoom).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _paragraphZoom[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _paragraphZoom[key];
    }
  });
});

var _portal = require("./portal");

Object.keys(_portal).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _portal[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _portal[key];
    }
  });
});

var _portalAsset = require("./portalAsset");

Object.keys(_portalAsset).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _portalAsset[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _portalAsset[key];
    }
  });
});

var _product = require("./product");

Object.keys(_product).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _product[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _product[key];
    }
  });
});

var _productPage = require("./productPage");

Object.keys(_productPage).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _productPage[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _productPage[key];
    }
  });
});

var _profile = require("./profile");

Object.keys(_profile).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _profile[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _profile[key];
    }
  });
});

var _search = require("./search");

Object.keys(_search).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _search[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _search[key];
    }
  });
});

var _searchPreview = require("./searchPreview");

Object.keys(_searchPreview).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _searchPreview[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _searchPreview[key];
    }
  });
});

var _searchSubscription = require("./searchSubscription");

Object.keys(_searchSubscription).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _searchSubscription[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _searchSubscription[key];
    }
  });
});

var _searchSuggestionsProvider = require("./searchSuggestionsProvider");

Object.keys(_searchSuggestionsProvider).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _searchSuggestionsProvider[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _searchSuggestionsProvider[key];
    }
  });
});

var _spaBootstrap = require("./spaBootstrap");

Object.keys(_spaBootstrap).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _spaBootstrap[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _spaBootstrap[key];
    }
  });
});

var _taxonomyOperations = require("./taxonomyOperations");

Object.keys(_taxonomyOperations).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _taxonomyOperations[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _taxonomyOperations[key];
    }
  });
});

var _ticket = require("./ticket");

Object.keys(_ticket).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _ticket[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _ticket[key];
    }
  });
});

var _timeline = require("./timeline");

Object.keys(_timeline).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _timeline[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _timeline[key];
    }
  });
});

var _translations = require("./translations");

Object.keys(_translations).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _translations[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _translations[key];
    }
  });
});

var _user = require("./user");

Object.keys(_user).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _user[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _user[key];
    }
  });
});

var _userProfile = require("./userProfile");

Object.keys(_userProfile).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _userProfile[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _userProfile[key];
    }
  });
});